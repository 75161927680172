import * as styles from './index.module.scss'

import { Link, navigate } from 'gatsby'
import React, { useState } from "react"
import { isLoggedIn, setUserToken } from '../../services/auth'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import AuthenticationForm from '../../components/authentication/form'
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SIGNIN_MUTATION = gql`
  mutation SinginMutation($login: String!, $password: String!){
    signIn(input: { login: $login, password: $password}) {
      success
      user {
        authenticationToken
      }
    }
  }
`

const Login = ({ data }) => {
  if (isLoggedIn()) {
    navigate('/presse')
  }

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const [signin, { data: response, loading, error }] = useMutation(SIGNIN_MUTATION,
    {
      variables: { login, password }
    }
  )

  const submitForm = (e) => {
    e.preventDefault()

    signin()
  }

  if (response && response.signIn && response.signIn.success) {
    setUserToken(response.signIn.user.authenticationToken)
    navigate('/presse')
  }

  return (
    <>
      <Seo title="Login Pressebereich" />
      <Layout>
        <AuthenticationForm title='Login Pressebereich'>
          <form onSubmit={submitForm} className={styles.form} data-loading={loading}>
            <div className={styles.errors}>
              {(error && error.message.replace('GraphQL error: ', ''))}
            </div>
            <div className={styles.fields}>
              <input className={styles.field} type="text" placeholder="Benutzername" value={login} onChange={(e) => setLogin(e.target.value)} />
              <input className={styles.field} type="password" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} />
              <button className={styles.button} type="submit">Anmelden</button>
            </div>
          </form>
          <div className={styles.actions}>
            <Link to='/registrieren'>Neu registrieren</Link>
            <Link to='/passwort/request'>Passwort vergessen?</Link>
          </div>
        </AuthenticationForm>
      </Layout>
    </>
  )
}

export default Login
